import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Header } from 'components';
import { I18nProvider } from 'i18n';
import './App.scss';

import Items from 'containers/Items';
import Heroes from 'containers/Heroes';
import Hero from 'containers/Hero';
import Games from 'containers/Games';
import Chronicles from 'containers/Chronicles';

function App() {
  return (
    <BrowserRouter>
      <I18nProvider>
        <div className="app-container">
          <Header />

          <main className="main">
            <Switch>
              <Route exact path="/">
                <Items />
              </Route>

              <Route exact path="/items">
                <Items />
              </Route>

              <Route exact path="/heroes">
                <Heroes />
              </Route>

              <Route exact path="/heroes/:id">
                <Hero />
              </Route>

              <Route exact path="/games">
                <Games />
              </Route>

              <Route exact path="/chronicles">
                <Chronicles />
              </Route>
            </Switch>
          </main>
        </div>
      </I18nProvider>
    </BrowserRouter>
  );
}

export default App;
