import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

const Button = ({ icon, children, onClick, className }) => (
  <button onClick={onClick} className={`button ${className || ''}`}>
    {icon && <i className="button__icon">{icon}</i>}
    {children && <span className="button__text">{children}</span>}
  </button>
);

Button.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default Button;
