import React from 'react';
import { useTranslations } from 'i18n';
import { HeroTag, HeroAvatar, HeroStats } from 'components';
import './HeroProfile.scss';

const HeroProfile = ({ hero }) => {
  const { t, locale } = useTranslations();

  const item = area => (hero.items[area] ? hero.items[area].data._title[locale] : t('labelEmpty'));

  return (
    <div className="hero-profile">
      <div className="hero-section">
        <div className="hero-title">
          <HeroTag hero={hero} hideValues />
        </div>
      </div>

      <div className="hero-section hero-section--avatar">
        <HeroAvatar hero={hero} />
      </div>

      <div className="hero-section">
        <HeroStats hero={hero} />

        <ul className="hero-items-list">
          <li>
            <b>🔸 {t('labelWeapon')}:</b> {item('weapon')}
          </li>
          <li>
            <b>🔸 {t('labelShield')}:</b> {item('shield')}
          </li>
          <li>
            <b>🔸 {t('labelHelmet')}:</b> {item('helmet')}
          </li>
          <li>
            <b>🔸 {t('labelArmor')}:</b> {item('armor')}
          </li>
          <li>
            <b>🔸 {t('labelGloves')}:</b> {item('gloves')}
          </li>
          <li>
            <b>🔸 {t('labelBoots')}:</b> {item('boots')}
          </li>
          <li>
            <b>🔸 {t('labelBelt')}:</b> {item('belt')}
          </li>
          <li>
            <b>🔸 {t('labelCloak')}:</b> {item('cloak')}
          </li>
          <li>
            <b>🔸 {t('labelAmulet')}:</b> {item('amulet')}
          </li>
          <li>
            <b>🔸 {t('labelRing')}:</b> {item('ring')}
          </li>
          <li>
            <b>🔸 {t('labelBag')}:</b> {item('bag')}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HeroProfile;
