import React from 'react';
import './HeroAvatar.scss';

const keys = {
  0: 'light',
  1: 'dark'
};

const HeroAvatar = ({ hero }) => {
  const { avatar, tendency } = hero;
  const key = keys[tendency];
  const src = avatar
    ? `${process.env.PUBLIC_URL}/images/avatars/${key}${avatar.fileNumber}.png`
    : `${process.env.PUBLIC_URL}/images/avatars/empty.png`;

  return (
    <div className="hero-avatar">
      <img src={src} alt="test" />
    </div>
  );
};

export default HeroAvatar;
