import React from 'react';
import { useTranslations } from 'i18n';
import { Link } from 'react-router-dom';
import { Button } from 'components';
import './Header.scss';

const locales = [
  {
    code: 'en',
    icon: '🇬🇧'
  },
  {
    code: 'uk',
    icon: '🇺🇦'
  },
  {
    code: 'ru',
    icon: '🇷🇺'
  },
  {
    code: 'es',
    icon: '🇪🇸'
  },
  {
    code: 'hi',
    icon: '🇮🇳'
  }
];

const Header = () => {
  const { t, locale, changeLocale } = useTranslations();

  return (
    <header className="header">
      <div className="header__logo">
        <img src={require('./images/logo.png')} alt="Arena Game" />
      </div>

      <nav className="header__menu">
        <div className="header__menu-item">
          <a href="https://t.me/arenagametelegrambot" target="_blank" rel="noopener noreferrer">
            <Button icon="🎮">{t('menu.label.play')}</Button>
          </a>
        </div>
        <div className="header__menu-item">
          <Link to="/items">
            <Button icon="🗡">{t('menu.label.items')}</Button>
          </Link>
        </div>
        <div className="header__menu-item">
          <Link to="/heroes">
            <Button icon="🎖">{t('menu.label.heroes')}</Button>
          </Link>
        </div>
        <div className="header__menu-item">
          <Link to="/clans">
            <Button icon="🏰">{t('menu.label.clans')}</Button>
          </Link>
        </div>
        <div className="header__menu-item">
          <Link to="/games">
            <Button icon="⚔">{t('menu.label.games')}</Button>
          </Link>
        </div>
        <div className="header__menu-item">
          <Link to="/chronicles">
            <Button icon="📜">{t('menu.label.chronicles')}</Button>
          </Link>
        </div>
        <div className="header__menu-item">
          <a
            href={`https://telegra.ph/Arena-Game-Library-07-${locale === 'ru' ? '24' : '25'}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button icon="📖">{t('menu.label.library')}</Button>
          </a>
        </div>
      </nav>

      <nav className="header__menu">
        {locales.map(locale => (
          <div className="header__menu-item header__menu-item--lang">
            <Button icon={locale.icon} className="button-lang" onClick={() => changeLocale(locale.code)} />
          </div>
        ))}
      </nav>
    </header>
  );
};

export default Header;
