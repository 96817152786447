export const set = (key, value) => localStorage.setItem(key, value);

export const get = key => localStorage.getItem(key);

const methods = {
  set,
  get
};

export default methods;
